@media screen and (max-width: 575px) {
  .coming-soon-image {
    width: 80% !important;
  }
}
@media screen and (min-width: 576px) {
  .coming-soon-image {
    width: 80% !important;
  }
}
@media screen and (min-width: 768px) {
  .coming-soon-image {
    width: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .coming-soon-image {
    width: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .coming-soon-image {
    width: 30% !important;
  }
}

.membership-container {
  text-align: center;
  margin: 2% auto;
  background: #ffffff;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.membership-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 2%;
}

.membership-description {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  width: 60%;
  border: 0;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 3%;
  width: 100%;
  border-bottom: 0.5px solid #ededed;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #545454;
  transition: color 0.3s, border-bottom 0.3s;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.tab.active {
  color: #ff6600;
  border-bottom: 2px solid #ff6600;
  font-size: 16px;
  font-weight: 500;
}

.toggle-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 2%;
}

.toggle-text {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #1b1c1e !important;
}

.toggle-switch span {
  font-size: 16px;
  color: #666;
  font-weight: 500;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #ff6600;
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .membership-title {
    font-size: 20px;
  }
  .membership-description {
    font-size: 14px;
  }
  .tabs .tab {
    font-size: 14px;
    padding: 8px 16px;
  }
  .toggle-switch span {
    font-size: 14px;
  }
}
