body {
  font-family: "Inter", sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Logo.css */

.logo-container {
  max-width: 100%;
  text-align: center;
  clear: both;
}

/* .logo-image {
  max-width: 100%;
  height: 70px;
} */

/* Adjust the size for specific breakpoints if needed */
/* For XL screens */
@media screen and (min-width: 1200px) {
  .scrollable-filter-column {
    max-height: 80vh; /* Adjust the max height as needed */
    overflow-y: auto;
    display: block !important;
  }
  .filter-sort-section {
    display: none;
  }
  .logo-image {
    height: 120px;
    width: 180px;
  }
  .show-icon-mobile-header {
    display: none !important;
  }
}
/* For lg screens */
@media screen and (min-width: 992px) {
  .download-section-2 {
    display: flex;
    align-items: start;
  }
  .product-service-image-height {
    height: 250px;
    border-radius: 16px;
    object-fit: contain;
    width: 90%;
    border: 1px solid #dadada;
  }
  .variant-box-style {
    width: 60%;
  }
  .filter-sort-section {
    border: 1px solid #dadada;
    padding: 1%;
    margin-bottom: 2%;
  }
  .seacrh-image {
    object-fit: contain;
    aspect-ratio: 3/2;
  }
  .logo-image {
    height: 120px;
    width: 180px;
  }
  .show-icon-mobile-header {
    display: none !important;
  }
}
@media screen and (min-width: 769px) {
  .mobile-width {
    width: 90% !important;
  }
  .download-section-2 {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .product-service-image-height {
    border-radius: 16px;
    object-fit: contain;
    border: 1px solid #dadada;
    height: 200px;
  }
  .variant-box-style {
    width: 100%;
  }
  .scrollable-filter-column {
    display: none;
  }
  .filter-sort-section {
    border: 1px solid #dadada;
    padding: 1%;
    margin-bottom: 2%;
  }
  .seacrh-image {
    object-fit: contain;
    aspect-ratio: 1/1;
  }
  /* .show-icon-mobile-header {
    display: none !important;
  } */
}
/* For md screens */
@media screen and (min-width: 768px) {
  .logo-image {
    height: 120px;
    width: 180px;
  }
  .logo-container {
    max-width: 100% !important;
  }
  .mobile-width {
    width: 100% !important;
  }
  .background-image {
    width: 40%;
  }
  .container {
    max-width: 100% !important;
  }
  .download-section-2 {
    display: flex;
    align-items: start;
  }
  .show-icon-mobile-header {
    width: 70%;
    display: flex;
    justify-content: end;
  }

  .scrollable-filter-column {
    display: none;
  }
  .filter-sort-section {
    border: 1px solid #dadada;
    padding: 1%;
    margin-bottom: 2%;
  }
}
/* For sm(small) screens */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .download-section-2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-service-image-height {
    height: 180px;
    border-radius: 16px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
  .variant-box-style {
    width: 100%;
  }
  .scrollable-filter-column {
    display: none;
  }
  .filter-sort-section {
    border: 1px solid #dadada;
    padding: 1%;
    margin-bottom: 2%;
  }
}
/* For xs(extra small) screens 575px */
@media screen and (max-width: 575px) {
  .product-service-image-height {
    height: 150px;
    border-radius: 16px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
  .scrollable-filter-column {
    display: none;
  }
  .filter-sort-section {
    border: 1px solid #dadada;
    padding: 1%;
    margin-bottom: 2%;
  }
  .seacrh-image {
    object-fit: contain;
    aspect-ratio: 2/1;
  }
  .logo-image {
    height: 100px;
    width: 60px;
  }
  .show-icon-mobile-header {
    width: 70%;
    display: flex;
    justify-content: end;
  }
  .align-item-center {
    display: none !important;
  }
}
/* For small phone menu icon style */
@media (max-width: 391px) {
  .show-icon-mobile-header {
    width: 50%;
    display: flex;
    justify-content: end;
  }
  .product-service-image-height {
    height: 150px;
    border-radius: 16px;
    object-fit: cover;
  }
  .variant-box-style {
    width: 70%;
  }
  .scrollable-filter-column {
    display: none;
  }
  .filter-sort-section {
    border: 1px solid #dadada;
    padding: 1%;
    margin-bottom: 2%;
  }
  .seacrh-image {
    object-fit: contain;
    aspect-ratio: 2/1;
  }
}

.align-item-center {
  align-items: center;
  flex-grow: 0;
}

.nav-link {
  color: #1b1c1e;
}

.custom-toggle-color {
  /* Use !important to ensure the style is applied */
  color: red;
}

.navbar-brand {
  max-width: 10%;
}

.container {
  margin-left: 1% !important;
  margin-right: 1% !important;
  min-width: 98%;
}

.navbar-color {
  color: red !important;
}

.dot {
  background-color: #e97101 !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background-color: #1b1c1e !important;
}

.react-multi-carousel-track {
  align-items: center;
}

.alignment-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  width: 40% !important;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

.form-check-input:checked {
  background-color: #e97101 !important;
  border-color: #e97101 !important;
}

.scrollable-product-column {
  max-height: 80vh; /* Adjust the max height as needed */
  overflow-y: auto;
  margin-bottom: 1%;
  scrollbar-width: none;
}

.rbt-menu-pagination-option {
  text-align: center !important;
  color: #e97101 !important;
  font-weight: 600 !important;
}

.input-group > .rbt:not(:last-child) .form-control {
  border: 0px solid #dadada;
}

.PhoneInput {
  border: #dadada;
  border-width: 2px;
  border-style: solid;
  padding: 2%;
  height: 46px;
  border-radius: 12px;
}

.PhoneInputInput {
  border: none;
}

:focus-visible {
  outline: none;
  /* border-width: 0px; */
}

.login-modal-style {
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 3% !important;
}
.modal-content {
  border-radius: 24px !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #dadada !important;
}
/* Increase specificity */
label .custom-radio:checked {
  background-color: #e97101; /* Change background color for checked state */
}

/* Reset default styles */
.custom-radio {
  -webkit-appearance: none; /* Remove default styles in WebKit browsers */
  -moz-appearance: none; /* Remove default styles in Firefox */
  appearance: none; /* Remove default styles in modern browsers */
  width: 16px; /* Set custom width */
  height: 16px; /* Set custom height */
  border: 2px solid #dadada; /* Border color for unchecked state */
  border-radius: 50%; /* Make it round */
  margin-right: 2%;
}

/* Style for checked state */
.custom-radio:checked {
  background-color: #e97101; /* Change background color for checked state */
}

.form-control {
  padding-top: 0.265rem !important;
  padding-bottom: 0.265rem !important;
}

.form-floating > label {
  color: #545455;
}

#basic-typeahead-single {
  transform: translate(0px, 34px) !important;
}

.modal-fullscreen .modal-body {
  overflow-y: visible !important;
}

/* About us and patner section */

@media (max-width: 575px) {
  .image-column {
    order: 2;
    margin-bottom: 5%;
  }

  .text-column {
    order: 3;
    margin-bottom: 5%;
  }

  .section-title {
    order: 1;
  }
}

.modal-header .btn-close {
  margin: 0 !important;
}

.react-player__preview {
  border-radius: 12px;
}

iframe {
  border-radius: 12px;
}

/* App Container */
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

/* Plans Container */
.plans-container {
  display: flex;
  margin-top: 3%;
  justify-content: center;
  width: 100%;
}

/* Plan Card */
.plan-card {
  position: relative;
  background: white;
  -webkit-backdrop-filter: blur(135.8px);
  backdrop-filter: blur(135.8px);
  box-shadow: 14px 14px 42px 0 rgba(30, 30, 30, 0.04),
    inset 0 4px 4px 0 rgba(255, 255, 255, 0.1);
  border: solid 1px #dadada;
  border-radius: 24px;
  padding: 30px;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Push price section to the bottom */
  align-items: center;
  gap: 16px;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.plan-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.plan-card:hover {
  transform: translateY(-10px);
  background: linear-gradient(to top right, #fcfcfc, #ffe7ce);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Plan Icon */
.plan-icon {
  font-size: 2rem;
  color: #6a0dad;
  display: flex;
  justify-content: flex-start;
}

.plan-name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10%;
}

.plan-description {
  font-size: 0.9rem;
  color: #666;
  width: 100%; /* Full width */
  line-height: 1.4;
  text-align: center;
  border: 0;
}

.plan-pricing {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-top: 8%;
}

.plan-price {
  color: #f97316;
  font-size: 24px;
  font-weight: bold;
  display: contents;
}

.original-price {
  color: #1b1c1e;
  font-size: 18px;
  text-decoration: line-through;
}

.plan-original-price {
  color: #1b1c1e;
  text-decoration: line-through;
  font-size: 18px;
}

.plan-duration {
  font-size: 16px;
  color: #7e7e7e;
}

/* Responsive Design */
@media (max-width: 600px) {
  .plan-card {
    width: 90%;
  }
}

@media (max-width: 992px) {
  .plans-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }

  .plan-card {
    width: 100%;
    max-width: 350px;
  }

  .tablet-navbar {
    display: none;
  }
}

@media (max-width: 768px) {
  .plans-container {
    /* flex-direction: column; */
    align-items: center;
  }

  .plan-card {
    width: 90%;
    padding: 16px;
  }

  .plan-price {
    font-size: 20px;
  }

  .plan-name {
    font-size: 18px;
  }

  .border-top {
    padding-top: 1.5rem;
  }

  .tablet-navbar {
    display: none;
  }
}

@media (max-width: 480px) {
  .plan-card {
    width: 95%;
    padding: 14px;
  }

  .plan-name {
    font-size: 16px;
  }

  .plan-price {
    font-size: 18px;
  }

  .plan-duration {
    font-size: 14px;
  }

  .referral-section-image {
    border-bottom-right-radius: 24px !important;
    border-top-left-radius: 0 !important;
  }

  .border-top {
    padding-top: 1.5rem;
  }

  .tablet-navbar {
    display: none;
  }
}

/* Save Banner */
.save-banner {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f97316; /* Orange */
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px 12px; /* Adjust for the design */
  border-radius: 0 8px 0 8px; /* Match the rounded corner look */
}

.save-banner span {
  display: block;
  text-align: center; /* Center the text */
}
