.pricing-card {
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  padding: 16px;
  width: 95%;
  max-width: 290px;
  background-color: white;
  box-shadow: 5px 5px 8px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}
@media (max-width: 768px) {
  .pricing-card {
    max-width: 100%;
  }
}

.pricing-card:hover {
  transform: translateY(-10px);
  background: linear-gradient(to top right, #fcfcfc, #ffe7ce);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plan-header {
  /* text-align: center; */
  margin-bottom: 16px;
}

.plan-header .icon {
  font-size: 24px;
  margin-bottom: 8px;
  display: block;
}

.plan-header h3 {
  font-size: 18px;
  margin: 0;
  color: #333;
}

.plan-price {
  text-align: center;
  margin-bottom: 16px;
}

.plan-price .price {
  font-size: 24px;
  font-weight: bold;
  color: #e97101;
}

.plan-price .duration {
  font-size: 16px;
  color: #999;
}

.plan-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: white;
  border: 2px solid #e97101;
  color: #e97101;
  font-size: 14px;
  font-weight: bold;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 16px;
}

.plan-button:hover {
  background-color: #e97101;
  color: white;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.plan-features li {
  display: flex;
  /* align-items: center; */
  margin-bottom: 5%;
  font-size: 14px;
  color: #333;
}

.icon-check {
  color: #1b1c1e; /* Matches your orange theme */
  margin-right: 8px;
  font-size: 18px; /* Adjust the size of the icon */
  flex-shrink: 0; /* Prevents the icon from shrinking */
}

.plan-features li span {
  line-height: 1.6; /* Adjust to center-align text with the icon */
}
