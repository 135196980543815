@media screen and (max-width: 575px) {
  .product-service-list-tablet-mobile {
    justify-content: center;
  }
  .referral-banner-for-desktop {
    display: none; /* Hide on tablets and mobiles */
  }
  .referral-banner-for-tablet-mobile {
    display: flex; /* Show only on tablets and mobiles */
  }
}
@media screen and (min-width: 576px) {
  .product-service-list-tablet-mobile {
    justify-content: center;
  }
  .referral-banner-for-desktop {
    display: none; /* Hide on tablets and mobiles */
  }
  .referral-banner-for-tablet-mobile {
    display: flex; /* Show only on tablets and mobiles */
  }
}
@media screen and (min-width: 768px) {
  .product-service-list-tablet-mobile {
    justify-content: center;
  }
  .referral-banner-for-desktop {
    display: none; /* Hide on tablets and mobiles */
  }
  .referral-banner-for-tablet-mobile {
    display: flex; /* Show only on tablets and mobiles */
  }
}
@media screen and (min-width: 992px) {
  .product-service-list-tablet-mobile {
    justify-content: center;
  }
  .referral-banner-for-desktop {
    display: flex; /* Hide on tablets and mobiles */
  }
  .referral-banner-for-tablet-mobile {
    display: none; /* Show only on tablets and mobiles */
  }
}
@media screen and (min-width: 1200px) {
  .product-service-list-tablet-mobile {
    justify-content: left;
  }
  .referral-banner-for-desktop {
    display: flex; /* Hide on tablets and mobiles */
  }
  .referral-banner-for-tablet-mobile {
    display: none; /* Show only on tablets and mobiles */
  }
}
